import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, children, style, className, variant, Icon, href }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-1 mr-2 -ml-1',
    primary: 'mr-2 -ml-1',
    secondary: 'mr-1',
    icon: 'stroke-1'
  }
  const loadingStyle = 'pointer-events-none opacity-75'
  const styles = {
    default: `text-gray-900 text-base font-light border border-gray-600 py-2 px-6 hover:text-black hover:border-gray-900`,
    primary: `text-white bg-black border border-black text-base font-normal py-2 px-6 hover:bg-gray-900 hover:border-gray-900`,
    secondary: `text-gray-800 text-xs font-light border border-gray-400 py-1 px-3 hover:text-black hover:border-gray-500`,
    icon: `text-black h-8 w-8 font-light border border-gray-600 py-1 px-1 box-content hover:text-black hover:border-gray-900`
  }
  return (
    <a href={href} target={href && href.match('http') ? '_blank' : ''}>
      <button
        onClick={async () => {
          if (loading) return
          setLoading(true)
          if (onClick) await onClick()
          setLoading(false)
        }}
        style={style}
        className={`relative overflow-hidden flex justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 ${styles[variant || 'default']} ${className} ${loading && loadingStyle}`}>
        {Icon && <Icon className={iconStyles[variant || 'default']} />}
        {children}
        {loading && <div className={`absolute w-full h-full p-1 ${variant === 'primary' ? 'bg-black' : 'bg-white'}`}><FiLoader className="stroke-2 rotating box-border w-full h-full" /></div>}
      </button>
    </a>
  )
}
