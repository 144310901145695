import React from 'react'
import { Helmet } from 'react-helmet'

export default function Headers({ title, photo }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || 'Studio Harcourt'}</title>
      <link rel="icon" type="image/png" href="/favicon.png" />
      <meta property="og:image" content={photo} />
    </Helmet>
  )
}
